import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/perliia/perliia/src/templates/api.mdx.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Un colis est un ensemble de produits ou de palettes à livrer`}</p>
    <h3>{`Attributs`}</h3>
    <div className="attributs">
      <ul>
        <li parentName="ul"><strong parentName="li">{`id`}</strong>{` `}<em parentName="li">{`UUID`}</em>{` `}<br /></li>
        <li parentName="ul"><strong parentName="li">{`name`}</strong>{` `}<em parentName="li">{`string`}</em>{` `}<br />{`
Nom du colis`}</li>
        <li parentName="ul"><strong parentName="li">{`type`}</strong>{` `}<em parentName="li">{`string`}</em>{` `}<br />{`
Type de colis parmi les types suivants :`}<br /></li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}><strong parentName="th">{`Type`}</strong></th>
            <th parentName="tr" {...{
              "align": null
            }}><strong parentName="th">{`Code`}</strong></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Cagette`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`crate`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Carton`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`box`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Sac ou filet`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`bag`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Palox`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`palox`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Palette ISO`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`iso_pallet`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Palette EPAL`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`epal_pallet`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Demi-palette EPAL`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`demi_pallet`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Quart-palette EPAL`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`quart_pallet`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Sac Isotherme`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`cooler_bag`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Glacière`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`cooler`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Bac à viande`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`meat_tray`}</inlineCode></td>
          </tr>
        </tbody>
      </table>
      <ul>
        <li parentName="ul"><strong parentName="li">{`quantities`}</strong>{` `}<em parentName="li">{`number`}</em>{` `}<br />{`
Nombre de colis`}</li>
        <li parentName="ul"><strong parentName="li">{`categories`}</strong>{` `}<em parentName="li">{`string`}</em>{` `}<br />{`
Catégorie(s) du colis parmi les types suivants :`}<br /></li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}><strong parentName="th">{`Type`}</strong></th>
            <th parentName="tr" {...{
              "align": null
            }}><strong parentName="th">{`Code`}</strong></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Aliments emballés`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`packed_food`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Aliments en vrac`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`food_bulk`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Aliments en vrac BIO`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`food_bulk_bio`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Fruits et légumes`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`fruit_vegetable`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Fruits et légumes BIO`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`fruit_vegetable_bio`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Oeufs`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`egg`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Oeufs BIO`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`egg_bio`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Fromages`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`cheese`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Fromages BIO`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`cheese_bio`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Boulangerie`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`bakery`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Boulangerie BIO`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`bakery_bio`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Viandes`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`meat`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Viandes BIO`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`meat_bio`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Poissons`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`fish`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Poissons BIO`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`fish_bio`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Non alimentaire emballé`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`packed_non_food`}</inlineCode></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Non alimentaire`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`non_food`}</inlineCode></td>
          </tr>
        </tbody>
      </table>
      <ul>
        <li parentName="ul"><strong parentName="li">{`length`}</strong>{` `}<em parentName="li">{`number`}</em>{` `}<br />{`
Longueur du colis en cm`}</li>
        <li parentName="ul"><strong parentName="li">{`width`}</strong>{` `}<em parentName="li">{`number`}</em>{` `}<br />{`
Largeur du colis en cm`}</li>
        <li parentName="ul"><strong parentName="li">{`height`}</strong>{` `}<em parentName="li">{`number`}</em>{` `}<br />{`
Hauteur du colis en cm`}</li>
        <li parentName="ul"><strong parentName="li">{`weight`}</strong>{` `}<em parentName="li">{`number`}</em>{` `}<br />{`
Poids du colis en kg`}</li>
        <li parentName="ul"><strong parentName="li">{`stackable`}</strong>{` `}<em parentName="li">{`boolean`}</em>{` `}<br />{`
Si le colis est empilable indiquer `}<em parentName="li">{`true`}</em></li>
        <li parentName="ul"><strong parentName="li">{`pallet`}</strong>{` `}<em parentName="li">{`boolean`}</em>{` `}<br />{`
Si le colis est une palette, indiquer `}<em parentName="li">{`true`}</em></li>
        <li parentName="ul"><strong parentName="li">{`maxTemperature`}</strong>{` `}<em parentName="li">{`number`}</em>{` `}<br />{`
Température maximum que peut supporter le colis`}</li>
        <li parentName="ul"><strong parentName="li">{`onSiteDuration`}</strong>{` `}<em parentName="li">{`number`}</em>{` `}<br />{`
Temps passé sur le lieu de présence`}</li>
        <li parentName="ul"><strong parentName="li">{`unloadingDuration`}</strong>{` `}<em parentName="li">{`number`}</em>{` `}<br />{`
Temps passé sur le lieu de livraison`}</li>
      </ul>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      